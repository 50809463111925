import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getUsersAndRoles, setUserId } from "../../actions/user";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import EditarPerfis from "./perfis";
import CreateUser from "./createUser";
import Usuario from "./usuario";
import { formataData } from "../Financial/util";
import { rolesDescription } from "../../constants/roles";
import ButtomComponent from '../commons/ButtonComponent'

class Roles extends React.Component {
  state = { open: false, createUserOpen: false, usuarioEscritorioOpen: false };

  componentDidMount() {
    this.props.getUsersAndRoles();
  }

  handleRolesOpen = () => {
    this.setState({ open: true });
  };
  handleCreateUserOpen = () => {
    this.setState({ createUserOpen: true });
  };

  handleUsuarioOpen = () => {
    this.setState({ usuarioOpen: true });
  };

  handleUsuarioClose = () => {
    this.setState({ usuarioOpen: false });
  };

  handleRolesClose = () => {
    this.setState({ open: false });
  };

  handleCreateUserClose = () => {
    this.setState({ createUserOpen: false });
    this.props.getUsersAndRoles();
  };

  render() {
    const { classes, users, loading } = this.props;
    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.areaOperacoes}
          >
            <Grid container item md={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <ButtomComponent
                  variant="insert"
                  onClick={() => this.handleCreateUserOpen()}
                >
                  Cadastrar novo usuário
                </ButtomComponent>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            justifyContent="center"
            alignItems="center"
          >
            <MaterialTable
              style={{ width: "calc(100% - 32px)", marginTop: 10 }}
              columns={[
                { title: "Nome Completo", field: "displayName" },
                { title: "Email", field: "email" },
                {
                  title: "Perfis",
                  field: "roles",
                  render: (row) =>
                    row.roles &&
                    row.roles.map((r) => rolesDescription[r]).join(", "),
                },
              ]}
              data={users}
              title="Usuários"
              actions={[
                {
                  icon: "lock",
                  tooltip: "Editar perfis",
                  onClick: (_, row) =>
                    this.props.setUserId(row.id) && this.handleRolesOpen(),
                },
                {
                  icon: "person",
                  tooltip: "Editar usuário",
                  onClick: (_, row) =>
                    this.props.setUserId(row.id) && this.handleUsuarioOpen(),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
              }}
              localization={tableI18n}
              detailPanel={(row) => this.renderDetail(row)}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </Grid>
          <EditarPerfis
            open={this.state.open}
            handleClose={this.handleRolesClose}
          />
          <CreateUser
            open={this.state.createUserOpen}
            handleClose={this.handleCreateUserClose}
          />
          <Usuario
            open={this.state.usuarioOpen}
            handleClose={this.handleUsuarioClose}
          />
        </Container>
      </React.Fragment>
    );
  }

  renderDetail(usuario) {
    const { logs } = usuario;
    const { classes } = this.props;
    if (logs) {
      return (
        <Grid container spacing={2} className={classes.detail}>
          {_.values(logs).map((item) => {
            return (
              <Grid item sm={12}>
                <Typography variant="body2" color="textSecondary">
                  Log:{" "}
                </Typography>
                <Typography variant="body1">{item.type}</Typography>
                <Typography variant="body1">{item.filename}</Typography>
                <Typography variant="body1">
                  {formataData(item.date)}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      );
    }
  }
}

const mapStateToProps = ({ userState: { users, loading } }) => ({
  users,
  loading,
});

Roles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getUsersAndRoles, setUserId })
)(withRouter(Roles));
