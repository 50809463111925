import { db } from '../firebase'
import { verificarConsignacao } from '../firebase/functions'
import { CONSIGNACOES_REQUEST, CONSIGNACOES_SET } from './types'

export const getConsignacoes = () => {
    return (dispatch) => {
        dispatch({ type: CONSIGNACOES_REQUEST });

        db.onceGetConsignacoes().then((snapshot) => {
            const consignacoes = snapshot.val();
            dispatch({ type: CONSIGNACOES_SET, consignacoes });
        }).catch((error) => {
            console.error("Erro ao obter consignações:", error);
        });
    };
};


export const verificaConsignacao = (year, month, idConsignacao) => {
    return async (dispatch) => {
        verificarConsignacao(year, month, idConsignacao);
    };
}

export const createConsignacao = (consignacao) => {
    return async (dispatch) => {
        try {
            const consignacaoId = await db.doCreateConsignacao(consignacao);
            return consignacaoId;
        } catch (error) {
            console.error("Erro ao criar consignação:", error);
        }
    };
};

export const verificarStatusConsignacao = (consignacaoId) => {
    return async (dispatch) => {
        try {
            const consignacao = await db.onceVerifyStatus(consignacaoId);

            if (consignacao) {
                return consignacao.status
            } else {
                console.log("Consignação não finalizada ou não encontrada.");
            }
        } catch (error) {
            console.error("Erro ao verificar o status da consignação:", error);
        }
    };
};