import * as routes from "../../constants/routes";

export default {
  admin_financeiro: {
    route: routes.FINANCIAL,
    name: "Financeiro",
    vector: "./img/financeiro.svg",
    altText: "Financeiro",
  },
  visualizador_financeiro: {
    route: routes.FINANCIAL,
    name: "Financeiro",
    vector: "./img/financeiro.svg",
    altText: "Financeiro",
  },
  gestor_usuario: {
    route: routes.FILIADOS,
    name: "Filiados",
    vector: "./img/filiados.svg",
    altText: "Filiados",
  },
  visualizador_usuario: {
    route: routes.FILIADOS,
    name: "Filiados",
    vector: "./img/filiados.svg",
    altText: "Filiados",
  },
  editor_filiado: {
    route: routes.FILIADOS,
    name: "Filiados",
    vector: "./img/filiados.svg",
    altText: "Filiados",
  },
  gestor_sistema: {
    route: routes.ROLES,
    name: "Perfis",
    vector: "./img/perfis.svg",
    altText: "Perfis",
  },
  aprovador: {
    route: routes.APROVADOR,
    name: "Aprovador",
    vector: "./img/aprovador.svg",
    altText: "Aprovador",
  },
  gestor_evento: {
    route: routes.EVENTOS,
    name: "Eventos",
    vector: "./img/eventos.svg",
    altText: "Eventos",
  },
  visualizador_evento: {
    route: routes.EVENTOS,
    name: "Eventos",
    vector: "./img/eventos.svg",
    altText: "Eventos",
  },
  gestor_interacao: {
    route: routes.INTERACOES,
    name: "Interações",
    vector: "./img/interacoes.svg",
    altText: "Interações",
  },
  gestor_funcionarios: {
    route: routes.FUNCIONARIOS,
    name: "Funcionários",
    vector: "./img/funcionarios.svg",
    altText: "Funcionários",
  },
  juridico: {
    route: routes.JURIDICO,
    name: "Jurídico",
    vector: "./img/juridico.svg",
    altText: "Jurídico",
  },
  escritorio_juridico: {
    route: routes.ESCRITORIO_JURIDICO,
    name: "Processos",
    vector: "./img/juridico.svg",
    altText: "Processos",
  },
  visualizador_juridico: {
    route: routes.JURIDICO,
    name: "Jurídico",
    vector: "./img/juridico.svg",
    altText: "Jurídico",
  },
  comunicacao: {
    route: routes.COMUNICACAO,
    name: "Comunicação",
    vector: "./img/comunicacoes.svg",
    altText: "Comunicação",
  },
  filiado: {
    route: routes.FILIADO,
    name: "Meu perfil",
    vector: "./img/modulo-filiados.svg",
    altText: "Meu perfil",
  },
  politico_sindical: {
    route: routes.POLITICO_SINDICAL,
    name: "Sindical",
    vector: "./img/politico-sindical.svg",
    altText: "Político-Sindical",
  },
  visualizador_contratos: {
    route: routes.CONTRATOS,
    name: "Contratos",
    vector: "./img/contrato.svg",
    altText: "Contratos",
  },
  pesquisa_satisfacao: {
    route: routes.PESQUISA_DE_SATISFACAO,
    name: "Satisfação",
    vector: "./img/satisfacao.svg",
    altText: "Satisfação",
  },
  autoridades: {
    route: routes.AUTORIDADES,
    name: "Autoridades",
    vector: "./img/autoridade.svg",
    altText: "Autoridades"
  },
  consignacao: {
    route: routes.CONSIGNACAO,
    name: 'Consignação',
    vector: './img/consignacao.svg',
    altText: 'Consignação'
  },
  visualizador_consignacao: {
    route: routes.CONSIGNACAO,
    name: 'Consignação',
    vector: './img/consignacao.svg',
    altText: 'Consignação'
  },
  sindex: {
    route: routes.SINDEX,
    name: "Sindex",
    vector: "./img/sindex.svg",
    altText: "Sindex"
  }
};