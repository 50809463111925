import React from "react";
import _ from "lodash";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Container } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import styles from "./styles";
import SindilegisBar from "../commons/SindilegisBar";
import rolePage from "./rolePage";
import UnauthorizedPage from "../UnauthorizedPage";

class ProfilePage extends React.Component {
  state = { pages: [] };

  componentDidMount() {
    const { roles } = this.props.authUser;
    const pages = _.uniqBy(roles.map(r => rolePage[r]), "name");
    this.setState({ pages });

    if (pages.length === 1) {
      this.props.history.push(pages[0].route);
    }
  }

  render() {
    const { classes } = this.props;
    const { roles } = this.props.authUser;

    if (roles.length === 0) { 
      return <UnauthorizedPage />
    }

    return (
      <React.Fragment>
        <SindilegisBar />
        <Container>
          <Grid
            container
            spacing={2}
            className={classes.conteudo}
            hidden={false}
          >
            {this.state.pages.map(page => this.renderCard(page))}
          </Grid>
        </Container>
      </React.Fragment>
    );
  }

  renderCard({ name, route, vector, altText }) {
    const { classes } = this.props;
    return (
      <Grid item sm={2} key={route}>
        <Card
          className={classes.card}
          onClick={() => this.props.history.push(route)}
        >
          <CardActionArea>
            <CardContent>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item sm={12}>
                  <img src={vector} classes={classes.profilesVectors} alt={altText} />
                </Grid>
              </Grid>
              <Typography gutterBottom variant="h5" color="primary" align="center">
                {name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
)(withRouter(ProfilePage));
