import { db } from "./firebase";
import _ from "lodash";

export const doLogUser = (uid, log) => db.ref(`usuarios/${uid}/logs`).push(log);

export const onceGetUsers = () => db.ref("usuarios").once("value");

export const onceGetUser = (uid) => db.ref(`usuarios/${uid}`).once("value");

export const doSaveUser = (id, user) => db.ref(`usuarios/${id}`).update(user);

export const doCreateFiliado = (id, user) => db.ref(`filiados/${id}`).set(user);

export const doSaveFiliado = (id, user) => db.ref(`filiados/${id}`).update(user);

export const onceGetFiliados = () => db.ref("filiados").once("value");

export const onceGetFiliado = id => db.ref(`filiados/${id}`).once("value");

export const doDeleteFiliado = (uid) => db.ref(`filiados/${uid}`).remove();

export const doCreateBeneficiario = (beneficiario) => db.ref(`beneficiarios`).push(beneficiario);

export const doSaveBeneficiario = (id, beneficiario) => db.ref(`beneficiarios/${id}`).update(beneficiario);

export const onceGetBeneficiarios = () => db.ref("beneficiarios").once("value");

export const onceGetBeneficiario = id => db.ref(`beneficiarios/${id}`).once("value");

export const doDeleteBeneficiario = (id) => db.ref(`beneficiarios/${id}`).remove();

export const onceGetRoles = uid => db.ref(`roles/${uid}`).once("value");

export const onceGetUserRoles = uid => db.ref(`roles`).once("value");

export const doSaveRole = (uid, role) => db.ref(`roles/${uid}/${role}`).set(true);

export const doDeleteRole = (uid, role) => db.ref(`roles/${uid}/${role}`).remove();

export const onceGetDependents = uid => db.ref("dependents").child(uid).once("value");

export const onceListDependents = () => db.ref("dependents").once("value");

export const doSaveDependent = (id, dependent) => db.ref(`dependents/${id}`).push(dependent);

export const doUpdateDependent = (filiadoId, id, dependent) => db.ref(`dependents/${filiadoId}/${id}`).update(dependent);

export const doDeleteDependent = (id, dependentId) => db.ref(`dependents/${id}/${dependentId}`).remove();

export const onceGetExpenses = () => db.ref("expenses").orderByChild("data_vencimento").once("value");

export const onceGetExpense = id => db.ref(`expenses/${id}`).once("value");

export const doSaveExpense = expense => db.ref(`expenses`).push(expense);

export const doDeleteExpense = id => db.ref(`expenses/${id}`).update({ excluido: true });

export const doUpdateExpense = (id, expense) => db.ref(`expenses/${id}`).update(expense);

export const onceGetHistoricoDespesa = id => db.ref(`audit_despesas/${id}`).once("value");

export const doIncluirAnexoDepesa = (id, path) => db.ref(`expenses/${id}/anexos`).push(path);

export const doIncluirAnexoDependente = (id, path) => db.ref(`dependents/${id}/anexos`).push(path);

export const onceGetHistoricoFiliado = id => db.ref(`audit_filiados/${id}`).once("value");

export const onceGetReceitas = () => db.ref("receitas").once("value");

export const onceGetReceita = id => db.ref(`receitas/${id}`).once("value");

export const doSaveReceita = expense => db.ref(`receitas`).push(expense);

export const doIncluirAnexoReceita = (id, path) => db.ref(`receitas/${id}/anexos`).push(path);

export const doUpdateReceita = (id, receita) => db.ref(`receitas/${id}`).update(receita);

export const doDeleteReceita = (id) => db.ref(`receitas/${id}`).remove();

export const doCreateItemDespesa = (item_despesa) => db.ref(`itens_despesa`).push(item_despesa);

export const doSaveItemDespesa = (id, item_despesa) => db.ref(`itens_despesa/${id}`).update(item_despesa);

export const onceGetItensDespesa = () => db.ref("itens_despesa").once("value");

export const onceGetItemDespesa = id => db.ref(`itens_despesa/${id}`).once("value");

export const doDeleteItemDespesa = (id) => db.ref(`itens_despesa/${id}`).remove();

export const doCreateEvento = (evento) => db.ref(`eventos`).push(evento);

export const doSaveEvento = (id, evento) => db.ref(`eventos/${id}`).update(evento);

export const onceGetEventos = () => db.ref("eventos").once("value");

export const onceGetEvento = id => db.ref(`eventos/${id}`).once("value");

export const doDeleteEvento = (id) => db.ref(`eventos/${id}`).remove();

export const doCreateFuncionario = (funcionario) => db.ref(`funcionarios/${funcionario.id}`).set(funcionario);

export const doSaveFuncionario = (id, evento) => db.ref(`funcionarios/${id}`).update(evento);

export const onceGetFuncionarios = () => db.ref("funcionarios").once("value");

export const onceGetFuncionario = id => db.ref(`funcionarios/${id}`).once("value");

export const doDeleteFuncionario = (id) => db.ref(`funcionarios/${id}`).remove();

export const doCreateAutoridadePolitica = async (politico) => {
    const newPoliticoRef = db.ref('autoridades_politicas').push();
    const id = newPoliticoRef.key;
    const data_criacao = new Date().toLocaleDateString('pt-BR');
    await newPoliticoRef.set({ ...politico, id, data_criacao });
    return id;
}

export const doSaveAutoridadePolitica = (id, politico) => {
    const data_atualizacao = new Date().toLocaleDateString('pt-BR');
    return db.ref(`autoridades_politicas/${id}`).update({ ...politico, data_atualizacao });
}

export const doIncluirAnexosAutoridade = (id, path) => db.ref(`autoridades_politicas/${id}/anexos`).push(path)

export const doRemoverAnexoAutoridade = async (anexoId, politicoId) => {

    const ref = db.ref(`autoridades_politicas/${politicoId}/anexos`)
    const snapshot = await ref.once('value');
    snapshot.forEach(childSnapshot => {
        const anexo = childSnapshot.val();
        if (anexo.id === anexoId) {
            return childSnapshot.ref.remove();
        }
    });
}

export const doIncluirPhotoAutoridade = (id, path) => db.ref(`autoridades_politicas/${id}/photos`).push(path);

export const onceGetAutoridadesPoliticas = () => db.ref("autoridades_politicas").once("value")

export const onceGetAutoridadePolitica = (id) => db.ref(`autoridades_politicas/${id}`).once("value")

export const doDeleteAutoridadePolitica = (id) => db.ref(`autoridades_politicas/${id}`).remove()

export const doCreateDocumento = (documento) => db.ref(`documentos`).push(documento);

export const doSaveDocumento = (id, documento) => db.ref(`documentos/${id}`).update(documento);

export const onceGetDocumentos = () => db.ref("documentos").once("value");

export const onceGetDocumento = id => db.ref(`documentos/${id}`).once("value");

export const doDeleteDocumento = (id) => db.ref(`documentos/${id}`).remove();

export const doIncluirAnexoDocumento = (id, path, email) => db.ref(`documentos/${id}`)
    .update({ data_carregamento: new Date().getTime(), anexo: path, email });

export const doCreateDocumentoTransparencia = (documento) => db.ref(`documentos_transparencia`).push(documento)

export const doSaveDocumentoTransparencia = (id, documento) => db.ref(`documentos_transparencia/${id}`).update(documento)

export const onceGetDocumentosTransparencia = () => db.ref("documentos_transparencia").once("value")

export const onceGetDocumentoTransparencia = (id) => db.ref(`documentos_transparencia/${id}`).once("value")

export const doDeleteDocumentoTransparencia = (id) => db.ref(`documentos_transparencia/${id}`).remove()

export const doIncluirAnexoDocumentoTransparencia = (id, path, email) => db.ref(`documentos_transparencia/${id}`)
    .update({ data_carregamento: new Date().getTime(), anexo: path, email })

export const doCreateInteracao = (filiadoId, interacao) => db.ref(`interacoes/${filiadoId}`).push(interacao);

export const doSaveInteracao = (id, interacao) => db.ref(`interacoes/${id}`).update(interacao);

export const onceGetInteracoes = () => db.ref("interacoes").once("value");

export const onceGetInteracao = id => db.ref(`interacoes/${id}`).once("value");

export const doDeleteInteracao = (id) => db.ref(`interacoes/${id}`).remove();

export const doCreateSuprimento = (suprimento) => db.ref(`suprimentos`).push(suprimento);

export const onceGetSuprimentos = () => db.ref("suprimentos").once("value");

export const onceGetSuprimento = id => db.ref(`suprimentos/${id}`).once("value");

export const doUpdateSuprimento = (id, receita) => db.ref(`suprimentos/${id}`).update(receita);

export const doDeleteSuprimento = (id) => db.ref(`suprimentos/${id}`).remove();

export const doCreateItemReceita = (item_receita) => db.ref(`itens_receita`).push(item_receita);

export const doSaveItemReceita = (id, item_receita) => db.ref(`itens_receita/${id}`).update(item_receita);

export const onceGetItensReceita = () => db.ref("itens_receita").once("value");

export const onceGetItemReceita = id => db.ref(`itens_receita/${id}`).once("value");

export const doDeleteItemReceita = (id) => db.ref(`itens_receita/${id}`).remove();

export const getNumeroAtendimento = () => {
    const year = new Date().getFullYear();
    const path = "juridico/numero_atendimento/" + year;
    return db.ref(path).once("value").then(r => {
        if (!r.exists()) {
            db.ref(path).push({ sequence: 1 })
            return year + '/0001'
        } else {
            return db.ref(path).once("value").then(v => {
                const key = _.keys(v.val())[0]
                db.ref(path + "/" + key).update({ sequence: v.val()[key].sequence + 1 })
                return year + "/" + (v.val()[key].sequence + 1).toString().padStart(4, '0')
            })
        }
    }).catch(e => console.error(e))
}

export const getNumeroProcedimento = () => {
    const year = new Date().getFullYear();
    const path = "juridico/numero_procedimento/" + year;
    return db.ref(path).once("value").then(r => {
        if (!r.exists()) {
            db.ref(path).push({ sequence: 1 })
            return year + '/0001'
        } else {
            return db.ref(path).once("value").then(v => {
                const key = _.keys(v.val())[0]
                db.ref(path + "/" + key).update({ sequence: v.val()[key].sequence + 1 })
                return year + "/" + (v.val()[key].sequence + 1).toString().padStart(4, '0')
            })
        }
    }).catch(e => console.error(e))
}

export const doCreateAtendimento = (escritorioAdvocaciaId, atendimento) => {
    return getNumeroAtendimento().then(numeroAtendimento => {
        atendimento.numero = numeroAtendimento
        return db.ref(`juridico/atendimentos/${escritorioAdvocaciaId}`).push(atendimento);
    })
}

export const onceGetAtendimentos = () => db.ref("juridico/atendimentos").orderByChild("data").once("value");

export const onceGetAtendimentosByEscritorio = (escritorioId) => db.ref(`juridico/atendimentos/${escritorioId}`).once("value");

export const doUpdateAtendimento = (atendimento) => db.ref(`juridico/atendimentos/${atendimento.escritorio.id}/${atendimento.id}`).update(_.omitBy(_.omit(atendimento, ['id']), _.isNil));

export const onceGetEscritoriosAdvocacia = () => db.ref("juridico/escritorios_advocacia").once("value");

export const onceGetEscritorioAdvocacia = (id) => db.ref(`juridico/escritorios_advocacia/${id}`).once("value");

export const doCreateEscritorioAdvocacia = (escritorio) => db.ref(`juridico/escritorios_advocacia`).push(escritorio);

export const doSaveEscritorioAdvocacia = (id, escritorio) => db.ref(`juridico/escritorios_advocacia/${id}`).update(escritorio);

export const doDeleteEscritorioAdvocacia = (id) => db.ref(`juridico/escritorios_advocacia/${id}`).remove();

export const doCreateProcesso = (processo) => db.ref(`juridico/processos`).push(processo);

export const onceGetProcessos = () => db.ref("juridico/processos").once("value");

export const onceGetProcesso = (id) => db.ref(`juridico/processos/${id}`).once("value")

export const onceGetProcessosByEscritorio = (escritorioId) => {
    return db.ref(`juridico/processos`).orderByChild('escritorio/id').equalTo(escritorioId).once('value');
};

export const doUpdateProcesso = (processo) => db.ref(`juridico/processos/${processo.id}`).update(_.omitBy(_.omit(processo, ['id']), _.isNil));

export const doIncluirAnexoEvento = (id, path, idProcesso) => { db.ref(`juridico/processos/${idProcesso}/eventos/${id}/anexos`).push(path) };

export const doDeleteEventoProcesso = (id, idProcesso) => {
    const ref = db.ref(`juridico/processos/${idProcesso}/eventos`);
    return ref.once('value').then(snapshot => {
        snapshot.forEach(childSnapshot => {
            const evento = childSnapshot.val();
            if (evento.id === id) {
                return childSnapshot.ref.remove();
            }
        });
    });
};

export const onceGetEventoProcesso = (id, processoId) => db.ref(`juridico/processos/${processoId}/eventos/${id}`).once("value")

export const doCreateObjetoProcesso = (item) => db.ref(`juridico/objetos`).push(item);

export const doSaveObjetoProcesso = (id, item) => db.ref(`juridico/objetos/${id}`).update(item);

export const onceGetObjetosProcesso = () => db.ref("juridico/objetos").once("value");

export const onceGetObjetoProcesso = id => db.ref(`juridico/objetos/${id}`).once("value");

export const doDeleteObjetoProcesso = (id) => db.ref(`juridico/objetos/${id}`).remove();

export const doCreateItemInteracao = (item_interacao) => db.ref(`itens_interacao`).push(item_interacao);

export const doSaveItemInteracao = (id, item_interacao) => db.ref(`itens_interacao/${id}`).update(item_interacao);

export const onceGetItensInteracao = () => db.ref("itens_interacao").once("value");

export const onceGetItemInteracao = id => db.ref(`itens_interacao/${id}`).once("value");

export const doDeleteItemInteracao = (id) => db.ref(`itens_interacao/${id}`).remove();

export const doSaveTema = (id, tema) => db.ref(`politico_sindical/temas/${id}`).update(tema);

export const doCreateTema = (tema) => db.ref(`politico_sindical/temas`).push(tema);

export const doIncluirDocumentoTema = (temaId, path) => db.ref(`politico_sindical/temas/${temaId}/documentos`).push(path);

export const doIncluirLinkTema = (temaId, link) => db.ref(`politico_sindical/temas/${temaId}/links`).push(link);

export const doCreateItemDestaqueTema = (temaId, itemDestaque) => db.ref(`politico_sindical/temas/${temaId}/itens_destaque`).push(itemDestaque);

export const doSaveItemDestaqueTema = (id, temaId, itemDestaque) => db.ref(`politico_sindical/temas/${temaId}/itens_destaque/${id}`).update(itemDestaque);

export const doDeleteItemDestaqueTema = (id, temaId) => db.ref(`politico_sindical/temas/${temaId}/itens_destaque/${id}`).remove();

export const doIncluirDocumentoItemDestaqueTema = (temaId, itemDestaqueId, path) => db.ref(`politico_sindical/temas/${temaId}/itens_destaque/${itemDestaqueId}/documentos`).push(path);

export const doIncluirLinkItemDestaqueTema = (temaId, itemDestaqueId, link) => db.ref(`politico_sindical/temas/${temaId}/itens_destaque/${itemDestaqueId}/links`).push(link);

export const onceGetItensDestaqueTema = (temaId) => db.ref(`politico_sindical/temas/${temaId}/itens_destaque`).once('value');

export const doCreatePessoaInstituicaoTema = (temaId, pessoaInstituicao) => db.ref(`politico_sindical/temas/${temaId}/pessoas_instituicoes`).push(pessoaInstituicao);

export const doSavePessoaInstituicaoTema = (id, temaId, pessoaInstituicao) => db.ref(`politico_sindical/temas/${temaId}/pessoas_instituicoes/${id}`).update(pessoaInstituicao);

export const doDeletePessoaInstituicaoTema = (id, temaId) => db.ref(`politico_sindical/temas/${temaId}/pessoas_instituicoes/${id}`).remove();

export const doCreateProcessoTema = (temaId, processo) => db.ref(`politico_sindical/temas/${temaId}/processos`).push(processo);

export const doSaveProcessoTema = (id, temaId, processo) => db.ref(`politico_sindical/temas/${temaId}/processos/${id}`).update(processo);

export const doDeleteProcessoTema = (id, temaId) => db.ref(`politico_sindical/temas/${temaId}/processos/${id}`).remove();

export const doCreateAcaoSindilegisTema = (temaId, acao) => db.ref(`politico_sindical/temas/${temaId}/acoes_sindilegis`).push(acao);

export const doSaveAcaoSindilegisTema = (id, temaId, acao) => db.ref(`politico_sindical/temas/${temaId}/acoes_sindilegis/${id}`).update(acao);

export const doDeleteAcaoSindilegisTema = (id, temaId) => db.ref(`politico_sindical/temas/${temaId}/acoes_sindilegis/${id}`).remove();

export const doIncluirDocumentoAcaoSindilegisTema = (temaId, acaoId, path) => db.ref(`politico_sindical/temas/${temaId}/acoes_sindilegis/${acaoId}/documentos`).push(path);

export const doIncluirLinkAcaoSindilegisTema = (temaId, acaoId, link) => db.ref(`politico_sindical/temas/${temaId}/acoes_sindilegis/${acaoId}/links`).push(link);

export const onceGetTemas = () => db.ref("politico_sindical/temas").once("value");

export const onceGetTemasPorSituacao = (situacao) => db.ref(`politico_sindical/temas`).orderByChild(`situacao`).equalTo(situacao).once(`value`);

export const onceGetLastFiveTemas = () => db.ref("politico_sindical/temas").orderByChild('createdAt').limitToLast(5).once("value");

export const onceGetTema = id => db.ref(`politico_sindical/temas/${id}`).once("value");

export const doDeleteTema = (uid) => db.ref(`politico_sindical/temas/${uid}`).remove();

export const doToggleSituacaoTema = (uid, situacao) => db.ref(`politico_sindical/temas/${uid}`).update({ situacao: situacao });

export const doCreateFiliadoProcesso = async (filiadoId, processoId, situacaoProcesso) => {
    try {
        await db.ref(`filiados_processos/${filiadoId}/${processoId}`).set({ filiadoId: filiadoId, processoId: processoId, situacao_processo: situacaoProcesso });
    } catch (error) {
        console.error("Erro ao criar relacionamento entre filiado e processo", error);
    }
}

export const onceGetFiliadosProcesso = () => db.ref(`filiados_processos`).once('value')

export const doDeleteFiliadosProcesso = async (processoId) => {
    try {
        const snapshot = await db.ref(`filiados_processos`).once('value');
        const filiadosProcessos = snapshot.val() || {};

        for (const filiadoId in filiadosProcessos) {
            if (filiadosProcessos[filiadoId][processoId]) {
                await db.ref(`filiados_processos/${filiadoId}/${processoId}`).remove();
            }
        }

    } catch (error) {
        console.error("Erro ao excluir relacionamentos de filiados com o processo", error);
    }
}

export const doDeleteFiliadoProcesso = async (filiadoId, processoId) => {
    try {
        await db.ref(`filiados_processos/${filiadoId}/${processoId}`).remove();
    } catch (error) {
        console.error("Erro ao excluir relacionamento de filiado com o processo", error);
    }
}

export const doCheckFiliadoProcesso = async (filiadoId, processoId) => {
    try {
        const snapshot = await db.ref(`filiados_processos/${filiadoId}/${processoId}`).once('value');
        return snapshot.exists();
    } catch (error) {
        console.error("Erro ao consultar relacionamento entre filiado e processo:", error);
        return false;
    }
};

export const doSavePrevisaoOrcamentaria = (ano, mes, centro_custo, item_despesa, valor) => db.ref(`previsoes_orcamentarias/${ano}/${mes}/${encode(centro_custo)}/${encode(item_despesa)}`).set(valor);

export const onceGetPrevisaoOrcamentaria = (ano, mes) => db.ref(`previsoes_orcamentarias/${ano}/${mes}`).once("value");

export function encode(decoded) {
    return encodeURIComponent(decoded).replace(/\./g, '%2E');
};

export function decode(encoded) {
    return decodeURIComponent(encoded.replace('%2E', '.'));
};

export const doCreateConsignacao = (consignacao) => {
    return new Promise((resolve, reject) => {
        const consignacaoRef = db.ref('consignacao/consignacoes').push();
        const consignacaoId = consignacaoRef.key;

        const consignacaoComId = { ...consignacao, id: consignacaoId };

        consignacaoRef.set(consignacaoComId, (error) => {
            if (error) {
                reject(error);
            } else {
                resolve(consignacaoId);
            }
        });
    });
};

export const onceGetConsignacoes = () => db.ref('consignacao').once('value')

export const onceVerifyStatus = async (idConsignacao) => {
    try {
        const consignacaoRef = db.ref(`consignacao/consignacoes/${idConsignacao}`);

        const snapshot = await consignacaoRef.once("value");
        const consignacao = snapshot.val();

        if (consignacao && consignacao.status === "Finalizado") {
            return consignacao;
        }

        return null;
    } catch (error) {
        console.error("Erro ao verificar consignação:", error);
        return null;
    }
};