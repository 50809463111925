import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Container,
  Button,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import SindilegisBar from "../commons/SindilegisBar";
import Loading from "../commons/Loading";
import CardArchive from "./CardArchive/CardArchive";
import {
  verificaConsignacao,
  getConsignacoes,
  createConsignacao,
  verificarStatusConsignacao,
} from "../../actions/consignacao";
import MuiAlert from "@material-ui/lab/Alert";
import { storage } from "../../firebase";
import { bindActionCreators } from "redux";
import Link from "@material-ui/core/Link";
import MaterialTable from "material-table";
import styles from "./index.module.css";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Consignacao = ({ authUser }) => {
  const [loading, setLoading] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [error, setError] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [loadingConsig, setLoadingConsig] = useState(false);

  const dispatch = useDispatch();
  const consignacoes = useSelector((state) => state.consignacao.consignacoes);
  const isVizualizador = authUser?.roles?.includes("visualizador_consignacao");

  useEffect(() => {
    dispatch(getConsignacoes());
  }, [dispatch]);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      dispatch(getConsignacoes(selectedYear, selectedMonth));
    }
  }, [selectedYear, selectedMonth, dispatch]);

  const handleFileUpload = () => setHasFiles(true);
  const clearFiles = () => setHasFiles(false);

  const handleVerificarConsignacao = async () => {
    setLoadingConsig(true);
    const data = new Date();
    const dataHoraFormatada = data.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    const consignacao = {
      startAt: `Início em ${dataHoraFormatada}`,
      status: "Em andamento",
    };

    if (!selectedYear || !selectedMonth) {
      setError("O mês e o ano são obrigatórios.");
      return;
    }

    try {
      setLoadingConsig(true);
      const idConsignacao = await dispatch(createConsignacao(consignacao));

      if (idConsignacao) {
        dispatch(
          verificaConsignacao(selectedYear, selectedMonth, idConsignacao)
        );
      } else {
        throw new Error("Erro ao criar consignação. ID não gerado.");
      }
      clearFiles();
      setError("");
      setIsVerifying(true);
      checkStatusConsignacao(idConsignacao);
    } catch (error) {
      setSnackbarMessage("Erro ao verificar a consignação: " + error.message);
      setSnackbarOpen(true);
    }
  };

  const checkStatusConsignacao = (idConsignacao) => {
    console.log(idConsignacao);
    let intervalId = setInterval(async () => {
      const status = await dispatch(verificarStatusConsignacao(idConsignacao));
      console.log(status);
      if (status === `Finalizado`) {
        clearInterval(intervalId);
        setSnackbarMessage("Verificação finalizada com sucesso");
        setSnackbarOpen(true);
        setIsVerifying(false);
        setLoadingConsig(false);
        await dispatch(getConsignacoes(selectedYear, selectedMonth));
      }
    }, 60000);
  };

  const consignacaoData = consignacoes?.[selectedYear]?.[selectedMonth];

  const consigs = consignacaoData
    ? Object.values(consignacaoData).filter((item) => item.tableData?.id !== 3)
    : [];

  const filiadosAtualizados = consignacaoData?.filiados_atualizados
    ? Object.values(consignacaoData.filiados_atualizados)
    : [];

  const isButtonDisabled = !hasFiles || isVerifying;
  const years = Array.from({ length: 7 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  const renderTable = (data, title) => (
    <Grid className={styles.table}>
      <MaterialTable
        columns={[
          {
            title: "Arquivo",
            field: "filename",
            render: (row) => (
              <Link
                component="button"
                variant="body2"
                onClick={() => storage.downloadAnexo(row.url)}
              >
                {row.filename}
              </Link>
            ),
          },
        ]}
        data={data}
        title={title}
        options={{
          actionsColumnIndex: -1,
          search: false,
          paging: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Nenhum dado disponível",
          },
        }}
      />
    </Grid>
  );

  return (
    <div>
      <SindilegisBar />
      <Loading loading={loading} />
      {loadingConsig ? (
        <Grid container className={styles.loading}>
          <Grid item>
            <div className={styles.divLoading}>
              <CircularProgress />
              <Typography variant="body1" className={styles.body1Loading}>
                Por favor, aguarde. Estamos verificando as consignações.
              </Typography>
              <Typography variant="body2" className={styles.body2Loading}>
                Esse processo pode demorar alguns minutos.
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="xl" className={styles.generalContainer}>
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid item sm={4}>
              <Typography
                variant="h5"
                align="start"
                gutterBottom
                className={styles.title}
              >
                CONSIGNAÇÃO
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.selectArea}>
              <Typography className={styles.titleSelectArea}>
                * Selecione o mês e o ano dos arquivos.
              </Typography>
              <Grid className={styles.gridFormControl}>
                <FormControl
                  variant="outlined"
                  style={{ minWidth: 120, marginRight: 20 }}
                >
                  <InputLabel>Mês</InputLabel>
                  <Select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    label="Mês"
                    disabled={isVerifying}
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ minWidth: 120 }}>
                  <InputLabel>Ano</InputLabel>
                  <Select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    label="Ano"
                    disabled={isVerifying}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {isVizualizador ? (
              <Grid item xs={12}>
                {renderTable(
                  filiadosAtualizados,
                  selectedMonth
                    ? `Filiados atualizados no mês ${selectedMonth}`
                    : "Filiados Atualizados"
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} className={styles.cardArea}>
                  {["Câmara", "Senado", "TCU"].map((title) => (
                    <CardArchive
                      key={title}
                      title={title}
                      onFileUpload={handleFileUpload}
                      selectedYear={selectedYear}
                      selectedMonth={selectedMonth}
                      onClearFiles={clearFiles}
                      disabled={isVerifying}
                    />
                  ))}
                </Grid>
                <Grid className={styles.errorArea}>
                  {error && (
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  )}
                  <Button
                    color="primary"
                    onClick={handleVerificarConsignacao}
                    disabled={isButtonDisabled}
                  >
                    <CheckCircleOutline /> Verificar consignação
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {renderTable(
                    consigs,
                    selectedMonth
                      ? `Consignação mês ${selectedMonth}`
                      : "Consignação"
                  )}
                  {renderTable(
                    filiadosAtualizados,
                    selectedMonth
                      ? `Filiados atualizados no mês ${selectedMonth}`
                      : "Filiados Atualizados"
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)}>{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getConsignacoes }, dispatch);

export default Consignacao;
